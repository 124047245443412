









import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import Icon from "@/components/Icon.vue"
import { TypedComponentProps } from "@/vue-props-types"
import ProjectFile from "@/models/ProjectFile"

const iconMappings = [
  {
    icon: "microsoft-word",
    extensions: ["doc", "dotx", "dot", "docm", "docx"],
    color: "#2A5493",
  },
  {
    icon: "microsoft-powerpoint",
    extensions: ["pptx", "pptm", "ppt"],
    color: "#C83F22",
  },
  {
    icon: "microsoft-excel",
    extensions: ["xls", "xlsx", "xlsm", "xlt", "xltx", "xlw", "csv"],
    color: "#227548",
  },
  {
    icon: "file-pdf",
    extensions: ["pdf"],
    color: "#E83D33",
  },
  {
    icon: "file-image-outline",
    extensions: ["jpg", "jpeg", "png", "gif"],
    color: "",
  },
  {
    icon: "folder-zip",
    extensions: ["zip"],
    color: "",
  },
  {
    icon: "microsoft-onenote",
    extensions: ["one"],
    color: "#7719AA",
  },
]

interface IconData {
  icon: string
  color: string
}

interface State {
  item: IconData | null
}

interface Props {
  size: number
  file: ProjectFile | null
}

const propsOptions = {
  size: {
    type: Number,
    default: 24,
  },
  file: {
    type: ProjectFile,
  },
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      Icon,
    },
    setup(props) {
      const state = reactive<State>({
        item: null,
      })

      const convertIconName = (name: string) => {
        const icon = iconMappings.find(ic =>
          ic.extensions.some(ext => name.endsWith(`.${ext}`))
        )
        return icon || ({ icon: "file", color: "" } as IconData)
      }

      const setState = (newFile: ProjectFile | null) => {
        if (!newFile) {
          state.item = null
          return
        }
        if (newFile.isFolder)
          state.item = { icon: "folder", color: "" } as IconData
        else if (newFile.isPackage && newFile.packageType === "oneNote")
          state.item = {
            icon: "microsoft-onenote",
            color: "#7719AA",
          } as IconData
        else state.item = convertIconName(newFile.name)
      }
      watch(
        () => props.file,
        newValue => {
          setState(newValue as ProjectFile)
        }
      )

      onMounted(() => {
        setState(props.file as ProjectFile)
      })

      return {
        state,
      }
    },
  }
)
